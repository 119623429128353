.mq__button-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
.mq__button-url {
  display: block;
}
.mq__button-arrow-graphic {
  height: 35px;
  width: 35px;
  -webkit-animation-name: mq__button-rotation-animation-reverse;
  -moz-animation-name: mq__button-rotation-animation-reverse;
  -ms-animation-name: mq__button-rotation-animation-reverse;
  -o-animation-name: mq__button-rotation-animation-reverse;
  animation-name: mq__button-rotation-animation-reverse;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -ms-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.mq__button {
  transform-style: preserve-3d;
  margin: 0 auto;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  overflow: hidden;
}
.mq__button-circle {
  position: relative;
  width: auto;
  background: transparent;
  padding-bottom: 100%;
  overflow: hidden;
}
.mq__rotating-text {
  font-family: var(--font-family-body), -apple-system, "Helvetica Neue",
    sans-serif;
  font-size: 0.8rem;
  text-transform: uppercase;
  fill: #ffffff;
  letter-spacing: 0.65rem;
}
.mq__button-round {
  position: absolute;
  left: -42px;
  top: -42px;
  width: 250px;
  height: auto;
  -webkit-animation-name: mq__button-rotation-animation;
  -moz-animation-name: mq__button-rotation-animation;
  -ms-animation-name: mq__button-rotation-animation;
  -o-animation-name: mq__button-rotation-animation;
  animation-name: mq__button-rotation-animation;
  -webkit-animation-duration: 20s;
  -moz-animation-duration: 20s;
  -ms-animation-duration: 20s;
  -o-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}
@-webkit-keyframes mq__button-rotation-animation {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes mq__button-rotation-animation {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-ms-keyframes mq__button-rotation-animation {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-o-keyframes mq__button-rotation-animation {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes mq__button-rotation-animation {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* REVERSE ROTATION */
@-webkit-keyframes mq__button-rotation-animation-reverse {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes mq__button-rotation-animation-reverse {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-ms-keyframes mq__button-rotation-animation-reverse {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-o-keyframes mq__button-rotation-animation-reverse {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes mq__button-rotation-animation-reverse {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}

@media only screen and (min-width: 768px) {
  .mq__rotating-text {
    font-size: 1rem;
    letter-spacing: 0.55rem;
  }
  .mq__button-round {
    left: -55px;
    top: -55px;
    width: 280px;
  }
}
