.connect__wrap {
  display: grid;
  grid-template-columns: minmax(auto, 1280px);
  max-width: 90%;
  min-height: 40vh;
  margin: 0 auto;
  position: relative;
  margin-bottom: 1rem;
}
.connect__socials {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-self: flex-start;
}
.connect__social-link {
  color: var(--color-03);
  font-size: 2rem;
}
.connect__container {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  max-width: 85%;
  justify-self: center;
}
/* .connect__email {
  grid-column-start: -3;
  grid-column-end: -1;
  grid-row-start: 2;
} */
.connect__text {
  max-width: 680px;
}
.connect__text p {
  font-size: 1.3rem;
  line-height: 1.8rem;
}

@media only screen and (min-width: 768px) {
  .connect__text p {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .connect__socials {
    align-self: flex-start;
    flex-direction: column;
  }
  .connect__container {
    grid-template-columns: 1fr 4fr 2fr;
  }
  .connect__email {
    grid-column-start: unset;
    grid-column-end: unset;
    grid-row-start: unset;
  }
}
@media only screen and (min-width: 1024px) {
}
