.btn {
  text-decoration: none;
  color: var(--color-03);
  font-family: var(--font-family-compact), -apple-system, "Helvetica Neue",
    sans-serif;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 3px;
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--color-03);
}
