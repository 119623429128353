@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-01: #c20000;
  --color-02: #161616;
  --color-03: #ffffff;
  --color-04: #3d3d3d;
  --color-05: rgba(0, 0, 0, 0.65);
  --color-06: #7fe9e0;
  --color-07: #878787;
  --color-08: #000000;
  --font-family-heading: "Neue Montreal Bold";
  --font-family-compact: "Right Grotesk Compact Dark";
  --font-family-medium: "Neue Montreal Medium";
  --font-family-body: "Trade Gothic LT";
  --font-family-thin: "Neue Montreal Thin";
  --transition-linear: linear 0.2s;
  --transition-swish: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}
@font-face {
  font-family: "Right Grotesk Compact Dark";
  src: url(../public/assets/fonts/RightGrotesk-CompactDark.otf)
    format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Neue Montreal Bold";
  src: url(../public/assets/fonts/NeueMontreal-Bold.otf) format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Neue Montreal Book";
  src: url(../public/assets/fonts/NeueMontreal-Book.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Neue Montreal Medium";
  src: url(../public/assets/fonts/NeueMontreal-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Neue Montreal Thin";
  src: url(../public/assets/fonts/NeueMontreal-Thin.otf) format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Trade Gothic LT";
  src: url(../public/assets/fonts/TradeGothic-LT.otf) format("opentype");
  font-weight: 200;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: var(--font-family-compact), -apple-system, "Helvetica Neue",
    sans-serif;
  color: var(--color-03);
  padding-bottom: 1rem;
}
body {
  background: var(--color-02);
}
body,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: var(--font-family-body), -apple-system, "Helvetica Neue",
    sans-serif;
  font-size: 1rem;
  color: var(--color-07);
  letter-spacing: 1px;
  line-height: 1.5rem;
}
p,
ol,
ul {
  padding-bottom: 1rem;
}
ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  width:100%;
}
.section__wrap {
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(auto, 1280px);
  overflow-x: clip;
}
.fancybox__content{
  width:auto !important;
  height:100% !important;
}
.fancybox__slide{

  padding:64px 40px !important;
}
