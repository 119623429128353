.hero__background{
  background-image: linear-gradient(
    to bottom,
    rgba(20, 20, 20, 0.2),
    rgba(20, 20, 20, 0.8)
  ),
  url("/public/assets/images/abstract-bg.jpg");
  height: 100vh;
  width: 100%;
  position:fixed;
  background-size: cover;
  background-position: 50% 50%;
  top:0 !important;
  left:0 !important;
  z-index:-1;
  opacity:1;
}
.hero {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  display: grid;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.hero__label {
  width: 320px;
  max-width: 320px;
  max-height: 160px;
  height: 100%;
}
.hero__intro-label {
  font-family: var(--font-family-medium), -apple-system, "Helvetica Neue",
    sans-serif;
  font-size: 1.3rem;
  letter-spacing: 1px;
  display: flex;
  margin-bottom: 0.5rem;
}
.hero__btn {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  color: #ffffff;
  background: transparent;
  border: none;
}
@media only screen and (min-width: 768px) {
  .hero__btn {
    bottom: 3rem;
    right: 3rem;
  }
  .hero__label {
    max-width: 600px;
    width: 600px;
  }
  .hero__intro-label {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero__label {
    max-width: 800px;
    width: 800px;
  }
}
