.about__section{
  margin-bottom:7rem;
}
.about__wrap {
  display: grid;
  grid-template-columns: minmax(auto, 1280px);
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
}
.about__photo-image{
  z-index:0;
}
.about__left-door{
  background: #0f0f0f;
  border:1px solid #3d3d3d;
  height:500px;
  width:500px;
  position:absolute;
  z-index:1;
  top:0;
  left:0;
  display:none;
}
.about__right-door{
  background: #0f0f0f;
  border:1px solid #3d3d3d;
  height:500px;
  width:500px;
  position:absolute;
  z-index:1;
  top:0;
  left:0;

  display:none;
}
.about__container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 1rem;
}
.about__photo {
  max-width: 90%;
  justify-self: center;
  position:relative;
}
.about__bio {
  justify-self: center;
  max-width: 85%;
  z-index:11;
}
.about__bio h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
}
.about__bio p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-03);
}
@media only screen and (min-width: 768px) {
  .about__left-door,.about__right-door{
    display:block;
  }
  .about__container {
    gap: 4rem;
  }
  .about__photo {
    max-width: 60%;

    justify-self: flex-start;
  }
  .about__bio h4 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3rem;
    margin-top: -13rem;
    max-width: 65%;
    margin-right: 0px;
    margin-left: auto;
  }
  .about__bio {
    justify-self: flex-end;
    max-width: 80%;
  }
}
@media only screen and (min-width: 1024px) {
  .about__left-door,.about__right-door{
    height:100%;
  }
  .about__photo {
    max-width: 100%;
  }
  .about__bio h4 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    margin-top: unset;
    max-width: unset;
    margin-right: unset;
    margin-left: unset;
  }
  .about__bio {
    max-width: 100%;
    margin-top: unset;
  }
  .about__container {
    grid-template-columns: 1fr 1fr;
  }
}
