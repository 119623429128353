.header {
  background: transparent;
  display: grid;
  position: fixed;
  width: 100%;
  z-index: 11;
}
.header__logo {
  padding: 1rem;
}
.header__logo-img {
  width: 60px;
  height: 51px;
}
.header__nav {
  display: grid;
  grid-template-columns: 100px 1fr;
}
.header__nav-wrap {
  display: grid;
  justify-self: end;
}
.header__nav-menu.active::before {
  opacity: 1;
  pointer-events: visible;
  transition: all 0.5s ease;
}
.header__nav-menu::before {
  content: "";
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  opacity: 0;
  background: var(--color-02);
  z-index: -1;
  transition: all 0.5s ease;
  pointer-events: none;
}
.header__nav-menu {
  position: absolute;
  top: 6rem;
  display: grid;
  right: -100%;
  justify-self: end;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  align-items: center;
  padding: 2rem;
  transition: all 0.5s ease;
}
.header__nav-menu.active {
  right: 0%;
  transition: all 0.5s ease;
}
.header__nav-menu-item {
  text-align: right;
}
.header__nav-menu-link {
  font-family: var(--font-family-compact), -apple-system, "Helvetica Neue",
    sans-serif;
  color: var(--color-03);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 3rem;
  line-height:normal;
}
.header__menu-icon {
  display: grid;
  align-self: center;
  padding: 2rem;
  font-size: 2rem;
  color: var(--color-03);
}
@media only screen and (min-width: 768px) {
  .header__nav-menu::before {
    display: none;
  }
  .header__nav-menu-item {
    text-align: center;
  }
  .header__nav-menu-link {
    font-size: 1rem;
  }
  .header__nav-menu,
  .header__nav-menu.active {
    position: relative;
    top: unset;
    right: unset;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .header__menu-icon {
    display: none;
  }
}
